<script setup lang="ts">
const dialogVisible = defineModel("dialogVisible", { type: Boolean });
const emit = defineEmits(["close"]);
const { data: user } = useAuth();
const router = useRouter();
const { t } = useI18n();

const UseLocalStore = useLocalStore();
const { tfaAlert } = storeToRefs(UseLocalStore);
const localePath = useLocalePath();

const closeMessage = (goToSettings?: boolean) => {
  tfaAlert.value.userId = user.value?.userId;
  tfaAlert.value.date = Date.now();
  dialogVisible.value = false;
  if (goToSettings) {
    router.push(localePath("/user/settings"));
  }
};
</script>

<template>
  <div class="tfa-alert-cont">
    <el-dialog
      v-model="dialogVisible"
      destroy-on-close
      :title="t('c-TFAAlert-2fa-disabled-title')"
      width="500"
      class="tfa-alert-popup"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <p>{{ t("c-TFAAlert-attention") }}</p>
      <p>
        {{ t("c-TFAAlert-message-1") }}
      </p>
      <p>
        {{ t("c-TFAAlert-message-2") }}
      </p>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="closeMessage()">{{
            t("c-TFAAlert-remind-me-later")
          }}</el-button>
          <el-button type="success" @click="closeMessage(true)">
            {{ t("c-TFAAlert-go-to-setup") }}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
.tfa-alert-cont {
  :deep(.tfa-alert-popup) {
    max-width: 95%;
  }
}
</style>
